<template>
  <v-app id="inspire">
    <v-container fluid>
      <v-overlay v-if="isLocallyLoading" opacity="0">
        <v-progress-circular
          :width="10"
          size="100"
          color="warning"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <v-row>
        <v-col cols="3">
          <div class="d-flex flex-column mt-4">
            <div class="">
              <v-btn
                class="ml-2 mt-1 elevation-0"
                elevation="0"
                color="green accent-4"
                small
                disabled
              >
                <v-icon>mdi-apps</v-icon> <span class="ml-4"> Crear </span>
              </v-btn>
              <v-btn
                class="ml-2 mt-1 elevation-1"
                elevation="0"
                @click="clearFilters"
                color="warning accent-4"
                small
                dark
              >
                <v-icon>mdi-trash-can</v-icon>
                <span class="ml-4"> Limpiar Filtro </span>
              </v-btn>

              <div class="ma-2" v-if="queryArguments.region !== null">
                <v-chip
                  @click:close="
                    selectedRegionId = null;
                    changeRegionHandler();
                  "
                  close
                  >{{
                    getItemPropFromArray(
                      regions,
                      selectedRegionId,
                      "abbreviation"
                    )
                  }}</v-chip
                >
              </div>
              <div class="ma-2" v-if="queryArguments.activity !== null">
                <v-chip
                  @click:close="
                    selectedActivityId = null;
                    changeActivityHandler();
                  "
                  close
                >
                  {{
                    getItemPropFromArray(activities, selectedActivityId, "name")
                  }}</v-chip
                >
              </div>
              <div class="ma-2" v-if="queryArguments.establishment !== null">
                <v-chip
                  @click:close="
                    selectedEstablishmentId = null;
                    changeEstablishmentHandler();
                  "
                  close
                >
                  {{
                    getItemPropFromArray(
                      establishments,
                      selectedEstablishmentId,
                      "name"
                    )
                  }}
                </v-chip>
              </div>
              <div
                class="ma-2"
                v-if="queryArguments.abstractCampaignId !== null"
              >
                <v-chip
                  @click:close="
                    selectedAbstractCampaignId = null;
                    selectedCampaignId = null;
                    changeAbstractCampaignHandler();
                  "
                  close
                >
                  {{
                    getItemPropFromArray(
                      abstractCampaigns,
                      selectedAbstractCampaignId,
                      "name"
                    )
                  }}
                </v-chip>
              </div>
              <div class="ma-2" v-if="queryArguments.campaignId !== null">
                <v-chip
                  @click:close="
                    selectedCampaignId = null;
                    changeCampaignHandler();
                  "
                  close
                >
                  {{
                    getItemPropFromArray(
                      campaigns,
                      selectedCampaignId,
                      "version",
                      "v"
                    )
                  }}
                </v-chip>
              </div>
              <div
                class="ma-2"
                v-if="
                  selectedActualHarvestCostStatus === null ||
                  selectedActualHarvestCostStatus === true
                "
              >
                <v-chip
                  @click:close="selectedActualHarvestCostStatus = false"
                  close
                >
                  <span v-if="selectedActualHarvestCostStatus">
                    Cosecha realizada
                  </span>
                  <span v-else> Cosecha proyectada </span>
                </v-chip>
              </div>
              <div
                class="ma-2"
                v-if="
                  selectedActualFreightCostStatus === null ||
                  selectedActualFreightCostStatus === true
                "
              >
                <v-chip
                  @click:close="selectedActualFreightCostStatus = false"
                  close
                >
                  <span v-if="selectedActualFreightCostStatus">
                    Flete realizado
                  </span>
                  <span v-else> Flete proyectado </span>
                </v-chip>
              </div>
            </div>

            <v-divider class="mt-4 p-2"> </v-divider>

            <v-expansion-panels
              flat
              :multiple="false"
              v-model="filtersPanel"
              class="mb-2"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <b :class="'ml-2 grey--text h2'"> Regiones </b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="">
                    <v-list>
                      <v-list-item-group
                        v-model="selectedRegionId"
                        color="indigo"
                        @change="changeRegionHandler"
                      >
                        <v-list-item
                          v-for="region in regions"
                          :key="region.id"
                          :value="region.id"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ region.name }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <b :class="'ml-2 grey--text h2'"> Cultivos </b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-sheet class="" color="#f5f5f5" elevation="0" width="100%">
                    <div class="pa-4">
                      <v-chip-group
                        v-model="selectedActivityId"
                        @change="changeActivityHandler"
                        active-class="primary--text"
                        column
                      >
                        <v-chip
                          v-for="activity in activities"
                          :key="activity.id"
                          :value="activity.id"
                        >
                          {{ activity.name }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-sheet>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-divider></v-divider>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <b :class="'ml-2 grey--text h2'"> Cosechas </b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="">
                    <v-list>
                      <v-list-item-group
                        v-model="selectedActualHarvestCostStatus"
                        @change="changeHarvestHandler"
                        color="indigo"
                      >
                        <v-list-item
                          v-for="item in actualHarvestCostOptions"
                          :key="item.id"
                          :value="item.value"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <b :class="'ml-2 grey--text h2'"> Fletes </b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="">
                    <v-list>
                      <v-list-item-group
                        v-model="selectedActualFreightCostStatus"
                        @change="changeFreightHandler"
                        color="indigo"
                      >
                        <v-list-item
                          v-for="item in actualFreightCostOptions"
                          :key="item.id"
                          :value="item.value"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <b :class="'ml-2 grey--text h2'"> Establecimientos </b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="">
                    <v-list>
                      <v-list-item-group
                        v-model="selectedEstablishmentId"
                        @change="changeEstablishmentHandler"
                        color="indigo"
                      >
                        <v-list-item
                          v-for="item in establishments"
                          :key="item.id"
                          :value="item.id"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <b :class="'ml-2 grey--text h2'"> Campañas </b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="">
                    <v-list>
                      <v-list-item-group
                        v-model="selectedAbstractCampaignId"
                        @change="changeAbstractCampaignHandler"
                        color="indigo"
                      >
                        <v-list-item
                          v-for="item in abstractCampaigns"
                          :key="item.id"
                          :value="item.id"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel v-if="!isAbstractCampaignSelected" disabled>
                <v-expansion-panel-header>
                  <b :class="'ml-2 grey--text h2'"> Versiones De Campaña </b>
                </v-expansion-panel-header>
              </v-expansion-panel>
              <v-expansion-panel v-else>
                <v-expansion-panel-header>
                  <b :class="'ml-2 grey--text h2'"> Versiones De Campaña </b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="">
                    <v-list>
                      <v-list-item-group
                        v-model="selectedCampaignId"
                        @change="changeCampaignHandler"
                        color="indigo"
                      >
                        <v-list-item
                          v-for="item in campaigns.filter(
                            (el) =>
                              el.abstractCampaignId ==
                              selectedAbstractCampaignId
                          )"
                          :key="item.id"
                          :value="item.id"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              >v{{ item.version }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>

        <v-col class="text-center" v-if="!isLocallyLoading" cols="9">
          <DenseCashflowTable
            v-if="activitySliceKeys.length > 0"
            :rows="activitySlices"
            :columns="activitySliceKeys"
            @rowClick="rowClickHandler"
          />
          <div v-else class="mt-5 pt-5">
            <span class="grey--text text-overline pa-5 ma-5">
              Sin actividades para mostrar
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 8px;
  opacity: 0.5;
  margin-top: 2px;
}
</style>

<script>
import DenseCashflowTable from "../components/WIP/DenseCashflowTable.vue";
import { mapGetters } from "vuex";
import { kRowsToItems } from "../managrx/services";
import { getRequest } from "../managrx/serverCall";
import { snakeToEspMap, toCamelMap } from "../managrx/objectUtils";
import { HARVEST_FREIGHT_ACTUAL_ITEMS } from "../managrx/constants";

export default {
  components: { DenseCashflowTable },
  name: "CFViewer",
  computed: {
    ...mapGetters([
      "campaigns",
      "abstractCampaigns",
      "establishments",
      "regions",
      "activities",
    ]),
    isAbstractCampaignSelected() {
      return this.selectedAbstractCampaignId !== null;
    },
    queryArguments() {
      return {
        region: this.selectedRegionId ? this.selectedRegionId : null,
        establishment: this.selectedEstablishmentId
          ? this.selectedEstablishmentId
          : null,
        activity: this.selectedActivityId ? this.selectedActivityId : null,
        abstractCampaignId: this.selectedAbstractCampaignId
          ? this.selectedAbstractCampaignId
          : null,
        campaignId: this.selectedCampaignId ? this.selectedCampaignId : null,

        // Null -> planned
        // True -> Actual
        // No other state is accepted for this elements (harvest and freight)
        // So false means no filter selected
        actualFreightCost:
          this.selectedActualFreightCostStatus ||
          this.selectedActualFreightCostStatus === null
            ? this.selectedActualFreightCostStatus
            : false,
        actualHarvestCost:
          this.selectedActualHarvestCostStatus ||
          this.selectedActualHarvestCostStatus === null
            ? this.selectedActualHarvestCostStatus
            : false,
      };
    },
  },
  async mounted() {
    this.isLocallyLoading = true;
    this.$store.dispatch("fetchSliceProductsList");
    this.$store.dispatch("fetchSliceCostInputPeriods");
    this.$store.dispatch("getEstablishments");
    this.$store.dispatch("getActivities");
    this.$store.dispatch("getAbstractCampaigns");
    this.$store.dispatch("getCampaigns");

    // this.resourceUtilization = ru;
    // this.cashflow = cf;
    this.isLocallyLoading = false;
  },
  methods: {
    getItemPropFromArray(array, id, prop, prefix) {
      let item = array?.find((el) => el?.id == id);
      if (item) {
        if (prefix) {
          return prefix + item?.[prop];
        }
        return item?.[prop];
      } else {
        return null;
      }
    },
    rowClickHandler(row) {
      this.$router.push("/activity-slice/" + row.activitySliceId);
    },
    changeActivityHandler() {
      this.userQueryUpdateHandler();
    },
    changeEstablishmentHandler() {
      this.userQueryUpdateHandler();
    },
    changeRegionHandler() {
      this.userQueryUpdateHandler();
    },
    changeAbstractCampaignHandler() {
      // We remove the version selected each time the abstract campaign selection change
      this.selectedCampaignId = null;
      this.userQueryUpdateHandler();
    },
    changeCampaignHandler() {
      this.userQueryUpdateHandler();
    },
    changeFreightHandler() {
      this.userQueryUpdateHandler();
    },
    changeHarvestHandler() {
      this.userQueryUpdateHandler();
    },
    clearFilters() {
      this.selectedRegionId = null;
      this.selectedEstablishmentId = null;
      this.selectedActivityId = null;
      this.selectedAbstractCampaignId = null;
      this.selectedCampaignId = null;

      // Null -> planned
      // True -> Actual
      // No other state is accepted for this elements (harvest and freight)
      // So false means no filter selected
      this.selectedActualFreightCostStatus = false;
      this.selectedActualHarvestCostStatus = false;

      this.userQueryUpdateHandler();
    },
    async userQueryUpdateHandler() {
      this.isLocallyLoading = true;

      let queryString = "q=";
      if (this.queryArguments?.region) {
        queryString =
          queryString + "region_id:" + this.queryArguments.region + ",";
      }

      if (this.queryArguments?.activity) {
        queryString =
          queryString + "activity_id:" + this.queryArguments.activity + ",";
      }

      if (this.queryArguments?.establishment) {
        queryString =
          queryString +
          "establishment_id:" +
          this.queryArguments.establishment +
          ",";
      }
      if (this.queryArguments?.abstractCampaignId) {
        queryString =
          queryString +
          "abstract_campaign_id:" +
          this.queryArguments.abstractCampaignId +
          ",";
      }
      if (this.queryArguments?.campaignId) {
        queryString =
          queryString + "campaign_id:" + this.queryArguments.campaignId + ",";
      }

      // Null -> planned
      // True -> Actual
      // No other state is accepted for this elements (harvest and freight)
      // So false means no filter selected
      if (this.queryArguments?.actualFreightCost !== false) {
        // If true query 1, else query null
        let queryValue = this.queryArguments?.actualFreightCost ? "1" : "null";
        queryString =
          queryString + "is_actual_freight_cost_tn:" + queryValue + ",";
      }
      if (this.queryArguments?.actualHarvestCost !== false) {
        // If true query 1, else query null
        let queryValue = this.queryArguments?.actualHarvestCost ? "1" : "null";
        queryString =
          queryString + "is_actual_harvest_cost:" + queryValue + ",";
      }
      if (queryString !== "q=") {
        queryString = queryString.slice(0, queryString.length - 1);

        let response = await getRequest(
          "/views/activity_slice_summary?" + queryString,
          true
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        } else {
          let activitySlices = kRowsToItems(
            response.data.rows,
            response.data.keys
          );
          let truncateLength = 15;
          this.activitySliceKeys = response.data.keys
            .map((keyString) => {
              return !keyString.toLowerCase().includes("id")
                ? {
                    tooltipText:
                      snakeToEspMap[keyString].charAt(0).toUpperCase() +
                      snakeToEspMap[keyString].slice(1),
                    text:
                      snakeToEspMap[keyString].length > truncateLength
                        ? (
                            snakeToEspMap[keyString].charAt(0).toUpperCase() +
                            snakeToEspMap[keyString].slice(1)
                          ).substring(0, truncateLength) + "..."
                        : snakeToEspMap[keyString].charAt(0).toUpperCase() +
                          snakeToEspMap[keyString].slice(1),
                    value: toCamelMap[keyString],
                  }
                : null;
            })
            .filter((el) => el !== null);
          this.activitySlices = activitySlices;
        }
      } else {
        this.activitySlices = [];
        this.activitySliceKeys = [];
      }
      this.isLocallyLoading = false;
    },
  },
  data() {
    return {
      harvestFreightActualItems: HARVEST_FREIGHT_ACTUAL_ITEMS,
      // Null -> planned
      // True -> Actual
      // No other state is accepted for this elements (harvest and freight)
      // So false means no filter selected
      selectedActualHarvestCostStatus: false,
      selectedActualFreightCostStatus: false,
      //
      filtersPanel: false,
      actualHarvestCostOptions: [
        { id: 1, name: "Cosecha realizada", value: true },
        { id: 2, name: "Cosecha proyectada", value: null },
      ],
      actualFreightCostOptions: [
        { id: 1, name: "Flete realizado", value: true },
        { id: 2, name: "Flete proyectado", value: null },
      ],
      activitySlices: [],
      activitySliceKeys: [],
      isLocallyLoading: false,
      selectedRegionId: null,
      selectedEstablishmentId: null,
      selectedActivityId: null,
      selectedAbstractCampaignId: null,
      selectedCampaignId: null,
      resourceUtilization: {
        rows: [],
        keys: [],
      },
      cashflow: {
        rows: [],
        keys: [],
      },
    };
  },
};
</script>
